import { map } from 'rxjs/operators';
// Service
import { TranslateService } from '@ngx-translate/core';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { AppState } from '@store/reducers';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Store, select } from '@ngrx/store';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { environment } from '@env/environment';
// Angular
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
// Model
import { Currency } from '@core/models/currency.model';
// Component
import { TransferWrapperModalComponent } from '@shared/transfer-wrapper-modal/transfer-wrapper-modal.component';
import { BankWrapperModalComponent } from '@shared/bank-wrapper-modal/bank-wrapper-modal.component';
// RxJS
import { Subscription, zip, forkJoin, Observable } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
// Third Party
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { LoadingService } from '@core/services/loading-service.service';
import { MemberBalance } from '@core/models/member-balance.model';
import svgIconList from 'assets/icons.json';

declare var $: any;
@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {
  svg: any = svgIconList;

  // Notification Variable
  messages$ = this.transferService.messages$;
  isSuccess = this.transferService.isSuccess

  // Form
  form: FormGroup;
  gameWalletForm: FormGroup;

  // Turnover Variable
  turnoverList: any;
  turnoverIsClicked = false;

  // Balance Variable
  wallet: MemberBalance;
  gameProvidersBalance: { rows: [any], total_game_balance: 0 }; // With balance
  providersList = []; // gameProvidersBalance + Main Wallet
  firstColumnProviders = [];
  secondColumnProviders = [];

  // Other varible
  providerBalancesIsLoading: number = 0;
  memberBalanceIsLoading: number = 0;
  isPromotionLoading: boolean = false;
  selectedTransferFrom: any;
  selectedTransferTo: any;
  selectedPromotion = null;
  amountButton = [50, 100, 200, 300, 500, 1000];
  checkValidity = false;
  isAllin = false;
  promotionList = []; // Transfer tab use
  promotionListGW = []; // Game wallet tab use
  selectedQuickPromotion = null;
  checkGameWalletValidity = false;
  promoTransferSuccess = false;
  isShowIDRRate: boolean;
  providerName: string;
  swapIcon: string = 'assets/images/member/swap.png';
  customClass: SweetAlertCustomClass = {
    popup: 'customPopupMaintenance',
  };
  providerBalanceInRefresh = [];

  // To hold all subscription and unsubscribe when leaving the page 
  private subscriptions: Subscription[] = [];


  @Input()
  isPerProvider = false;


  constructor(
    public dialogRef: MatDialogRef<TransferWrapperModalComponent>,
    private dashboardHttpService: DashboardHttpService,
    private transferService: PortalTransferHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    private walletHttpService: WalletHttpService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private loadingService: LoadingService
  ) {
    this.formInit();
    this.gameWalletFormInit();
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.providerBalancesLoadingBehaviourSubject.subscribe(val => {
        this.providerBalancesIsLoading = val;
      })
    );
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.memberBalanceLoadingBehaviourSubject.subscribe(val => {
        this.memberBalanceIsLoading = val;
      })
    );
    // Subscribe to get push update for member balance
    this.subscriptions.push(
      this.walletHttpService.memberBalance.subscribe(res => {
        this.wallet = res;
        if (this.wallet.currency_code != '-' && this.gameProvidersBalance != undefined) {
          this.setProviders();
        }
      })
    );
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameProvidersBalance = res;
        if (this.gameProvidersBalance.rows.length > 0 && this.wallet != undefined) {
          this.setProviders();
        }
      })
    );
  }

  ngOnInit() {
    this.getTurnover();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  // Start : Transfer Tab
  getTurnover() {
    this.gameProviderHttpService.getWeeklyTurnover().pipe(
      tap(res => {
        this.turnoverList = res;
        this.turnoverIsClicked = false;
      }),
      catchError(err => {
        this.turnoverIsClicked = false;
        return err;
      })
    ).subscribe();
  }

  onRefreshTurnover() {
    if (this.providerBalancesIsLoading == 0 && this.memberBalanceIsLoading == 0 && this.turnoverIsClicked == false) {
      this.turnoverIsClicked = true;
      this.getTurnover();
    }
  }

  onSelectTransferForm(provider) {
    if (provider.status === 1) {
      this.isShowIDRRate = false;
      this.selectedTransferFrom = provider;
      this.form.patchValue({
        amount: provider.balance,
        transfer_from: provider.id
      });
      if (this.selectedTransferTo !== undefined || this.selectedTransferFrom.id === this.selectedTransferTo?.id) {
        this.selectedTransferTo = undefined;
        this.form.patchValue({
          transfer_to: null
        });
        this.form.get('promotion_id').clearValidators();
        this.form.get('promotion_id').updateValueAndValidity();
      }
    }
  }

  onSelectTransferTo(provider) {
    this.selectedPromotion = null;
    this.form.patchValue({
      promotion_id: null
    });
    if (provider.status === 1) {
      this.selectedTransferTo = provider;
      if (this.wallet.currency_code === 'IDR') {
        switch (this.selectedTransferFrom['code']) {
          case 'KISS':
            if (this.selectedTransferTo?.code !== 'MEGA' || this.selectedTransferTo?.code !== 'MEGAC') {
              this.isShowIDRRate = true;
              this.providerName = this.selectedTransferFrom['name'];
              break;
            } else {
              this.isShowIDRRate = false;
              break;
            }
          case 'MEGA':
          case 'MEGAC':
            if (this.selectedTransferTo?.code !== 'KISS') {
              this.isShowIDRRate = true;
              this.providerName = this.selectedTransferFrom['name'];
              break;
            } else {
              this.isShowIDRRate = false;
              break;
            }
          default:
            if (this.selectedTransferTo?.code === 'MEGA' || this.selectedTransferTo?.code === 'MEGAC' || this.selectedTransferTo?.code === 'KISS') {
              this.isShowIDRRate = true;
              this.providerName = this.selectedTransferTo?.name;
            } else {
              this.isShowIDRRate = false;
            }
        }
      }
      this.form.patchValue({
        transfer_to: provider.id,
        promotion_id: 0
      });
      if (provider.has_promo === 1) {
        this.getPromotionList(provider);
      }
    }
  }

  onSwap() {
    if (this.selectedTransferTo?.id !== undefined && this.selectedTransferFrom['id'] !== undefined) {
      this.promotionList = [];
      const selectedTransferFrom = this.selectedTransferFrom;
      const selectedTransferTo = this.selectedTransferTo;
      this.onSelectTransferForm(selectedTransferTo);
      this.onSelectTransferTo(selectedTransferFrom);
      this.onSelectPromotion(0, 0, true);
    }
  }

  onSelectAmount(selectedAmount: number) {
    if (this.providerBalancesIsLoading == 0 && this.memberBalanceIsLoading == 0) {
      this.form.patchValue({ amount: selectedAmount });
    }
  }

  onSubmitForm() {
    if (sessionStorage.getItem('member_bank_accounts') === "[]" || sessionStorage.getItem('member_bank_accounts') === null) {
      this.dialog.open(BankWrapperModalComponent, {
        width: 'auto',
        data: {
          is_transfer: true
        }
      });
    }
    else {
      this.checkValidity = true;
      if (this.form.valid && this.form.get('amount').value >= 0) {
        const data: tranferFormData = {
          amount: this.form.value.amount,
          promotion_id: this.form.value.promotion_id === null ? 0 : this.form.value.promotion_id,
          transfer_from: this.form.value.transfer_from,
          transfer_to: this.form.value.transfer_to
        }
        // If there is no promotion to be applied, do normal transfer function
        if (data.promotion_id == 0) {
          this.submitData(data);
        } else {
          // If there is promotion claimed
          // First we find the wallet balance that user want to transfer to
          const game_balance = this.providersList.find(x => x.id == data.transfer_to);
          // If found and the wallet balance is greater than 0
          if (game_balance && game_balance.balance > 0) {
            // Do confirmation pop up
            this.alertPop(game_balance, data);
          } else {
            // If wallet balance is 0, do normal transfer function
            this.submitData(data);
          }
        }
        this.checkValidity = false;
      }
    }
  }

  onHover(mouseIn?: boolean) {
    this.swapIcon = mouseIn ? 'assets/images/member/swap-active.png' : 'assets/images/member/swap.png';
  }

  showMaintenance() {
    Swal.fire({
      icon: 'info',
      iconHtml: '!',
      customClass: this.customClass,
      html: '<div class="text-center m-t-20">' + this.translateService.instant('Under Maintenance') + '</div>'
    });
  }

  private formInit() {
    this.form = new FormGroup({
      amount: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      promotion_id: new FormControl(0),
      transfer_from: new FormControl(null, [Validators.required]),
      transfer_to: new FormControl(null, [Validators.required])
    });
  }

  private setProviders() {
    const half = Math.ceil(this.gameProvidersBalance.rows.length / 2);
    this.firstColumnProviders = this.gameProvidersBalance.rows.slice(0, half);
    this.secondColumnProviders = this.gameProvidersBalance.rows.slice(-half);
    // Remove the middle only when providers are in odd number count
    if (this.gameProvidersBalance.rows.length % 2 != 0) this.secondColumnProviders.splice(0, 1);
    this.providersList = this.gameProvidersBalance.rows.slice(); // Use slice to clone
    this.providersList.unshift({ ...this.wallet, id: 0, image_path: 'assets/images/vendor/main-wallet.png', status: 1, name: this.translateService.instant('Main Wallet') });
    this.onSelectTransferForm({ ...this.wallet, id: 0, image_path: 'assets/images/vendor/main-wallet.png', status: 1, name: this.translateService.instant('Main Wallet') });
    this.selectedPromotion = null;
  }

  private sucessPromoPopUp(res: any) {
    // The wallet balance will be transferr amount + bonus amount as claiming promotion, the wallet balance will be always 0
    let new_wallet_balance = +res.data.rows.amount + +res.data.rows.bonus_amount;
    // Add currency code to the wallet balance
    const new_wallet_balance_text = this.wallet.currency_code + ' ' + new_wallet_balance.toFixed(2).toString();
    // Display confirmation popup
    Swal.fire({
      html: '<div class="msg-icon-greentick">' + this.svg.greenTickIcon + '</div>' +
            '<div class="text-center m-t-20">' +
              this.translateService.instant('Congratulations') +
            '</div>' + '<br>' +
            '<div class="text-center">' +
              this.translateService.instant('The promotion has been successfully applied.') +
            '</div>' +
            '<hr>' + 
            "<div class='col-12 form-group text-left'>" + 
            "<label class='text-capitalize'>" + this.translateService.instant('Game Provider') + "</label>" +
            "<input type='text' class='form-control' value='" + res.data.to_name + "' />" +
            "<label class='text-capitalize m-t-10'>" + this.translateService.instant('New Wallet Balance') + "</label>" +
            "<input type='text' class='form-control' value='" + new_wallet_balance_text + "' />" +
            "</div>"
    });
  }

  private alertPop(gameData: GameBalance, formData: tranferFormData) {
    // Display confrimation message
    Swal.fire({
      html: "<div class='col-12 form-group text-left'>" + 
            "<label class='text-capitalize'>" + this.translateService.instant('Game Provider') + "</label>" +
            "<input type='text' class='form-control' value='" + gameData.name + "' />" +
            "<label class='text-capitalize m-t-10'>" + this.translateService.instant('Game Wallet Balance') + "</label>" +
            "<input type='text' class='form-control' value='" + gameData.balance + "' />" +
            "</div>" +
            '<div class="text-center m-t-20 alert-text" style="font-size: 11px">' +
              this.translateService.instant('You have a remaining balance in the selected game wallet.') + '<br>' +
              this.translateService.instant('This balance will be transferred back to the main wallet before the promotion application.') +
            '</div>' + '<br>' +
            '<div class="text-center" style="font-size: 11px">' +
              this.translateService.instant('Click YES to proceed with the transfer and promotion application.') +
            '</div>',
      confirmButtonText: this.translateService.instant('YES'),
      showDenyButton: true,
      showConfirmButton: true,
      denyButtonText: this.translateService.instant('NO'),
      reverseButtons: true,
      showCloseButton: true,
      customClass: {
        denyButton: 'deny-button',
        confirmButton: 'confirm-button',
      }
    }).then(result => {
      // If yes
      if (result.isConfirmed) {
        // Prepare param for transferring the remaining game wallet balance to main wallet first
        this.promoTransferSuccess = false;
        const dataFromGameToMain = {
          amount: gameData.balance,
          promotion_id: 0,
          transfer_from: gameData.id,
          transfer_to: 0
        }
        this.submitDataTwo(dataFromGameToMain).toPromise()
          .then(() => {
            if (this.promoTransferSuccess) {
              const dataFromMainToGameWithPromo = formData;
              this.submitData(dataFromMainToGameWithPromo);
            }
          })
          .catch((err) => {
            throw err;
          });

      }
    });
  }

  private submitDataTwo(data: any, fromPromoTransfer: boolean = false): Observable<any> {
    return this.transferService.transfer(data).pipe(
      tap(res => {
        this.promoTransferSuccess = res.success;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
  // End : Transfer Tab

  // Start : Game Wallet Tab
  private gameWalletFormInit() {
    this.gameWalletForm = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(1)]),
      promotion_id: new FormControl(0)
    });
  }

  toggleGameProvider(provider, columnToClose, test: any) {
    this.checkGameWalletValidity = false;
    if (provider.status === 1 && provider.suspend_transfer_in_status === 0) {
      $(`#gameWallet${columnToClose} .toggle div`).addClass('collapsed')
      $(`#gameWallet${columnToClose} .toggle .show`).removeClass('show')
      // $(`.toggle`).not(`#${provider.code}Content`).removeClass('toggle-active');
      // $(`#${provider.code}Content`).closest('.toggle').siblings().find('.toggle-content').removeClass('show');
      // $(`#${provider.code}Content`).toggleClass('toggle-active').find(`#${provider.code}Content`).toggleClass('show');
      this.gameWalletForm.reset();
      if (!$(`#${provider.code}Content`).hasClass('show')) {
        this.getPromotionList(provider, true);
      } else {
        this.promotionListGW = [];
      };
    }
  }

  onAllIn(providerId: number) {
    this.isAllin = true;
    this.form.patchValue({
      promotion_id: 0,
      transfer_from: 0,
      transfer_to: providerId
    });
    return this.transfer().subscribe();
  }

  onQuickTransfer(providerId: number) {
    this.checkGameWalletValidity = true;
    if (this.gameWalletForm.valid) {
      const data = {
        amount: this.gameWalletForm.value.amount,
        promotion_id: this.selectedPromotion ? this.selectedPromotion.id : 0,
        transfer_from: 0,
        transfer_to: providerId
      }
      this.submitData(data);
    }
  }

  onRefreshProviderBalance(provider) {
    let balances = JSON.parse(sessionStorage.getItem('game_providers_balance'));
    this.providerBalanceInRefresh.push(provider.code);
    this.gameCategoryHttpService.getProviderBalanceBy(provider.code).pipe(
      tap(res => {
        balances.rows.map((balance) => {
          if (balance.code === provider.code) {
            balances['total_game_balance'] -= +balance.balance;
            balance.balance = res;
            balances['total_game_balance'] += +res;
          }
        })

        // Push the latest data into gameProvidersBalance BehaviorSubject
        this.gameCategoryHttpService.pushData(balances);

        // Set latest data into session storage
        sessionStorage.setItem('game_providers_balance', JSON.stringify(balances));
        this.providerBalanceInRefresh = this.providerBalanceInRefresh.filter(code => {return code != provider.code});
      })
    ).subscribe();
  }

  private transfer() {
    const data = (this.isAllin) ? { ...this.form.value, amount: 'all' } : this.form.value;
    return this.transferService.transfer(data).pipe(
      tap(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        if (res.success && !this.isPerProvider) {
          this.selectedPromotion = null;
          this.isAllin = false; // reset
        }
        this.refreshBalance();
        this.form.reset();
      }),
      catchError((error) => {
        this.getTurnover();
        this.selectedPromotion = null;
        this.isAllin = false; // reset
        this.refreshBalance();
        this.form.reset();
        throw error;
      })
    );
  }
  // End : Game Wallet Tab

  // Start : Shared
  onMultiplicand(formAmount: any, selectedPromo: any, provider?: any) {
    var finalBonus = this.onBonusAmount(formAmount, selectedPromo);
    var targetTypeValue = selectedPromo['target_type_name'].includes('incl') ? formAmount : 0; // 0 for exclude
    // Comment out to not include the balance in game provider wallet for turnover calculation
    // const totalTransferBonusAmount = ((Number(targetTypeValue) + Number(finalBonus) + Number(provider ? provider['balance'] : this.selectedTransferTo['balance'])).toFixed(3).slice(0,-1));
    const totalTransferBonusAmount = ((Number(targetTypeValue) + Number(finalBonus)).toFixed(3).slice(0, -1));
    var finalTotalTransferBonusAmount = Number(totalTransferBonusAmount);
    return Number(finalTotalTransferBonusAmount);
  }

  onTotalTurnOver(transferAmount: number, selectedPromo: any, provider?: any) {
    const finalTotalTransferBonusAmount = this.onMultiplicand(transferAmount, selectedPromo, provider);
    return Number(finalTotalTransferBonusAmount) * Number(selectedPromo.target_multiplier);
  }

  onBonusAmount(formAmount: any, selectedPromo: any) {
    const totalBonus = (formAmount * selectedPromo.bonus_rate).toFixed(3).slice(0, -1);
    var finalBonus = +totalBonus;
    if (+selectedPromo['max_bonus'] < +totalBonus && +selectedPromo['max_bonus'] > 0) {
      finalBonus = +selectedPromo['max_bonus'];
    }
    return finalBonus;
  }

  onSelectPromotion(promotion: any, ind: number, reset: boolean = false) {
    if (!reset && promotion === 0) {
      this.selectedQuickPromotion = {
        id: 0,
        name: this.translateService.instant('None')
      };
      this.selectedPromotion = {
        id: 0,
        name: this.translateService.instant('None')
      };
    } else if (reset) {
      this.selectedQuickPromotion = null;
      this.selectedPromotion = null;
    } else {
      const data = {
        ...promotion,
        name: promotion.name === null ? (this.translateService.instant('Promotion') + ' ' + ind) : promotion.name
      }
      this.selectedQuickPromotion = promotion;
      this.selectedPromotion = data;
      // Target type = Winloss incl. Transfer Amount
      // Set the multiplier+1 due to Target Amount = (Game Wallet Balance + Transfer Amount + Bonus) x Game Multiplier
      if (this.selectedPromotion.target_type == 2) {
        this.selectedPromotion.target_multiplier = this.selectedPromotion.target_multiplier + 1;
      }
    }
    this.form.patchValue({
      promotion_id: promotion === 0 ? 0 : promotion.id
    });
    if (promotion.promo_type === 4) {
      this.form.controls['amount'].setValidators(Validators.min(0));
    } else {
      this.form.controls['amount'].setValidators(Validators.min(1));
    }
    this.form.get('amount').updateValueAndValidity();
  }

  private submitData(data: any) {
    this.transferService.transfer(data).pipe(
      tap(res => {
        this.checkGameWalletValidity = false;
        this.isSuccess = res.success;
        // If there is no promotion claimed, use the old success message pop up
        if (res.data.rows.promotion_id == 0) {
          this.messages$.next(res.message);
        } else {
          // If there is a promotion claimed, use new message pop up
          this.sucessPromoPopUp(res);
        }
        this.selectedPromotion = null;
        this.selectedQuickPromotion = null;
        this.refreshBalance();
      }),
      catchError((error) => {
        this.checkGameWalletValidity = false;
        this.selectedPromotion = null;
        this.refreshBalance();
        throw error;
      })
    ).subscribe();
  }

  private getPromotionList(provider, isGameWalletTab = false) {
    this.isPromotionLoading = true;
    this.transferService.getPromotions(provider.id).pipe(
      tap(res => {
        if (!isGameWalletTab) {
          this.promotionList = res;
        } else {
          this.promotionListGW = res;
        }
        this.isPromotionLoading = false;
      }),
      catchError(err => {
        this.isPromotionLoading = false;
        return err;
      })
    ).subscribe();
  }

  // Refresh balance after transfer
  private refreshBalance() {
    forkJoin([
      this.gameCategoryHttpService.getGameCategoryListWithPromo(true),
      this.walletHttpService.getMemberBalance()
    ]).subscribe();
  }
  // End : Shared
}

class GameBalance {
  balance: string;
  name: string;
  code: string;
  id: number;
}

class tranferFormData {
  amount: number;
  promotion_id: number;
  transfer_from: number;
  transfer_to: number;
}
