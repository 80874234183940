// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordMsgResolver implements Resolve<boolean> {
  constructor() { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const userToken = localStorage.getItem('user_token');
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const skipUrls = ['/member/change-password'];

    if (userToken && !skipUrls.includes(state.url) && userData) {
      return userData;
    }
  }
}
