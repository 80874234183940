import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { BankHttpService } from '@core/services/bank-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, Inject, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthHttpService } from '@core/services/auth-http.service';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/reducers';
import { tap, delay, catchError, map } from 'rxjs/operators';
import { of, Subscription, forkJoin } from 'rxjs';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { BankAccountHttpService } from '@core/services/bank-account-http.service';
import { BankMaintenanceHourService } from '../../core/services/bank-maintenance-hour.service';
import { CryptoWalletHttpService } from '@core/services/crypto-wallet-http.service';
import { environment } from '@env/environment';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  svg: any = svgIconList;
  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  messages$ = this.auth.messages$;
  form: FormGroup;
  passwordInput = {
    isMasked: true,
    type: null,
    icon: null,
    isValidPassword: true
  };
  loading = false;
  checkValidity = false;
  currentCountry = localStorage.getItem('country_code');
  languageCode = localStorage.getItem('language_code');
  private subscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotion: any },
    public dialogRef: MatDialogRef<LoginModalComponent>,
    private router: Router,
    private auth: AuthHttpService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    public loadingBar: LoadingBarService,
    public dashboardHttpService: DashboardHttpService,
    private eventEmitterService: EventEmitterService,
    private walletHttpService: WalletHttpService,
    private bankHttpService: BankHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private bankAccountHttpService: BankAccountHttpService,
    private bankMaintenanceHourService: BankMaintenanceHourService,
    private cryptoWalletHttpService: CryptoWalletHttpService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.onPasswordMask();
    this.initializeForm();
    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  submit() {
    this.checkValidity = true;
    if (this.form.valid && !this.loading) {
      this.loading = true;
      this.loadingBar.start();
      const { username, password } = this.form.value;

      this.auth.login(username, password).pipe(
        tap((res: any) => {
          if (res) {
            this.eventEmitterService.onResetAnnouncementEmitter();
            this.eventEmitterService.onUpdateVIPEmitter();
          }
          this.store.dispatch(fromAuthActions.login({
            user: res.data.user,
            token: res.data.token,
          }));
          localStorage.setItem('verified_number', res.data.user.phone_validated);
          sessionStorage.setItem('session_user_checker', 'logged in');
          this.onCloseDialog();
          if (res.data.user.name !== '') {
            if (this.data?.promotion) { // this condition used after logged in from promo apply
              if (+this.data.promotion.apply_action === 2) {
                localStorage.setItem('promoTransfer', 'true');
              }
            }
            let interval = setInterval(() => {
              this.subscription = this.walletHttpService.memberBalance.subscribe(wallet => {
                if (wallet.currency_code != '-') {
                  if (wallet.balance <= 0) {
                    this.eventEmitterService.onAvailableProviderEmitter();
                    if (this.data === null && +res.data.user.pending_reward === 0) {
                      //TODO: remove this if confirmed no need redirect with the new MemberEvent noti
                      //this.router.navigate(['/member/']);
                    }
                  }
                  of(null).pipe(
                    delay(1000), tap(() => this.eventEmitterService.onTopBarTabEmitter()
                    )).subscribe();
                  clearInterval(interval);
                  this.subscription.unsubscribe();
                }
              })
            }, 500);
          }
          this.setSessionStorage(res.data.user.country_code);
          if (window.location.pathname.includes('slots')) {
            this.eventEmitterService.onUpdateSlots();
          }
          this.loading = false;
          this.loadingBar.complete();
          this.checkValidity = false;
          this.eventEmitterService.footerPaddingEmiter.emit(500);
          sessionStorage.removeItem('fishingSession');
          sessionStorage.removeItem('crashGameSession');
          sessionStorage.removeItem('slotSession');
          sessionStorage.removeItem('lotterySession');
          sessionStorage.removeItem('tableSession');
          sessionStorage.removeItem('casinoSession');
        }),
        catchError(err => {
          this.loading = false;
          this.loadingBar.complete();
          return err;
        })
      ).subscribe(
        response => {
          this.route.queryParams.subscribe(async (params: Params) => {
            if (window.location.pathname.includes('signup') || params['campaign'] !== undefined) {
              this.router.navigateByUrl('/');
            }
          });
        }
      );
    }
  }

  onPasswordMask() {
    this.passwordInput.isMasked = !this.passwordInput.isMasked;
    this.passwordInput.type = this.passwordInput.isMasked ? 'text' : 'password';
    this.passwordInput.icon = this.passwordInput.isMasked ? this.svg.viewPasswordIcon : this.svg.hidePasswordIcon;
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private setSessionStorage(countryCode: string) {
    this.bankHttpService.getAllBanks().subscribe(res => {
      sessionStorage.setItem('all_bank', JSON.stringify(res))
    });

    this.merchantBankHttpService.getMerchantBankDeposits().subscribe(res => {
      sessionStorage.setItem('member_merchant_bank', JSON.stringify(res))
      this.setBankMaintenanceHour();
    });
    this.bankAccountHttpService.getAll().subscribe(res => {
      sessionStorage.setItem('member_bank_accounts', JSON.stringify(res))
    });
    this.cryptoWalletHttpService.getAll().subscribe(res => {
      sessionStorage.setItem('member_crypto_accounts', JSON.stringify(res))
    });
    if (countryCode.toLocaleUpperCase() === 'SG') {
      this.bankHttpService.getPaymentGatewayBank('FP').subscribe(res => {
        sessionStorage.setItem('member_fpbank_code', JSON.stringify(res))
      });
    }
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(320)
        ])
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100)
        ])
      ]
    });
  }

  private setBankMaintenanceHour() {
    if (sessionStorage.getItem('member_merchant_bank') !== null) {
      let bank_ids = [];
      JSON.parse(sessionStorage.getItem('member_merchant_bank')).forEach(merchant_banks => {
        if (merchant_banks['bank_id']) {
          bank_ids.push(merchant_banks['bank_id']);
        }
      });
      this.bankMaintenanceHourService.getBankMaintenanceHours(bank_ids).subscribe(res => {
        localStorage.setItem('bank_maintenance_hour', JSON.stringify(res));
      });

    }
  }

}
