import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicFooterService } from '@core/services/dynamic-footer.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { of, Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from './../../../store/reducers';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { BottomBarService } from '@core/services/bottombar.service';

@Component({
  selector: 'app-dynamic-footer',
  templateUrl: './dynamic-footer.component.html',
  styleUrls: ['./dynamic-footer.component.scss'],
  animations: [
    trigger('seoSlider', [
      state('in', style({
        height: '*'
      })),
      state('out', style({
        height: '150px'
      })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ]),
  ]
})
export class DynamicFooterComponent implements OnInit, AfterViewInit {

  footerList: any[];
  version = localStorage.getItem('api_version');
  isSeoExpanded = false;
  playtechPage = localStorage.getItem('playtechCategory') !== null ? true : false;
  isLaunchPage = window.location.pathname === '/launch' ? true : false;
  countryCode = localStorage.getItem('country_code');
  currentPageSeoFooter = '';
  currentGameCategory = '';
  isLoggedIn$: Observable<boolean>;
  gameCategoryFooter = {
    'sports': ['wbet', 'cmd'],
    'casino': ['dg', 'bg', 'sexy', 'evo', 'sa'],
    'slots': ['cq9', 'kiss', 'mega', 'megac', 'pp', 'live', 'sg', 'next', 'haba'],
    'esports': ['cmd'],
  };
  pageHasOwnFooter = ['wbet', 'dg', 'bg', 'cq9', 'evo', 'sexy', 'sa', 'kiss', 'cmd', 'mega', 'megac', 'pp', 'haba', 'next', 'sg', 'live', 'cmd'];
  showRow = 0;
  lastRow = 0;

  sitePrefix = (environment.sitePrefix.toUpperCase() == 'BP9' || environment.sitePrefix.toUpperCase() == 'BP77') ? 'BP' : environment.sitePrefix.toUpperCase();
  seoFooterData = this.bottomBarService.contentDataDF;
  languageCode: string =  localStorage.getItem('language_code') ? localStorage.getItem('language_code').toUpperCase() : 'EN'; 

  @ViewChild("outlet", { read: ViewContainerRef }) outletRef: ViewContainerRef;
  @ViewChild("content", { read: TemplateRef }) contentRef: TemplateRef<any>;

  constructor(
    public translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
    private dynamicFooterService: DynamicFooterService,
    private store: Store<AppState>,
    private router: Router,
    private bottomBarService: BottomBarService
  ) {
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    of(null).pipe(
      delay(1700), tap(() => this.version = localStorage.getItem('api_version')
      )).subscribe();
    this.eventEmitterService.updateSeoFooterEmitter.subscribe((code: string) => {
      this.currentGameCategory = '';
      if (window.location.pathname.includes('esports')) {
        this.currentGameCategory = 'esports';
      } else if (window.location.pathname.includes('sport')) {
        this.currentGameCategory = 'sports';
      } else if (window.location.pathname.includes('casino')) {
        this.currentGameCategory = 'casino';
      } else if (window.location.pathname.includes('slots')) {
        this.currentGameCategory = 'slots';
      } else if (window.location.pathname.includes('/')) {
        this.currentGameCategory = 'main';
      }
      code = code == 'MEGAB' ? 'MEGA' : code;
      this.onChangeCurrentPageSeoFooter(code);
    });

    this.isLoggedIn$.subscribe(res => {
      of(null).pipe(
        delay(500), tap(() => {
          this.countryCode = localStorage.getItem('country_code');

          this.dynamicFooterService.getFooter().subscribe((res) => {
            this.footerList = res;
            this.lastRow = res[res.length - 1]['web_row'];
            this.eventEmitterService.footerPaddingEmiter.emit(500);
          });
        }
        )).subscribe();

      if (res) {
        this.outletRef.clear();
        this.outletRef.createEmbeddedView(this.contentRef);
      }
    });

  }
  ngAfterViewInit(): void {
    this.outletRef.createEmbeddedView(this.contentRef);
  }

  getCurrentYear() {
    return new Date().getFullYear()
  }

  counter(i: number) {
    return new Array(i);
  }

  updateShowRowSpace(number) {
    this.showRow = number;
  }

  onSlideSeo() {
    this.isSeoExpanded = !this.isSeoExpanded;
    this.eventEmitterService.footerPaddingEmiter.emit(500);
  }

  onChangeCurrentPageSeoFooter(code: string) {
    this.isSeoExpanded = false;
    this.currentPageSeoFooter = '';
    if (this.pageHasOwnFooter.includes(code.toLocaleLowerCase())) {
      this.currentPageSeoFooter = code.toLocaleLowerCase();
    }

    if (window.location.pathname === '/') {
      this.currentPageSeoFooter = 'main';
    }
  }

  hasIcons(item) {
    for (const i of item) {
      if (i.icon_path) {
        return true;
      }
    }

    return false;
  }

  onRedirect(link: string) {
    if (link !== null && link !== '') {
      if (link.includes('/external?link=')) {
        var redirectTO = link.replace('/external?link=', '');
        window.open(redirectTO, '_blank');
      } else {
        this.router.navigateByUrl(link);
      }
    }
  }
}
