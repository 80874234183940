import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MemberBalance } from '@core/models/member-balance.model';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { LoadingService } from '@core/services/loading-service.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, Subject, Subscription, zip } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-wallet-modal',
  templateUrl: './wallet-modal.component.html',
  styleUrls: ['./wallet-modal.component.scss']
})
export class WalletModalComponent implements OnInit, OnDestroy {

  // Notification Variable
  messages$ = new Subject<any[]>();
  isSuccess = false;
  flashStyle = false;

  // Balance Variable
  wallet: MemberBalance;
  gameProvidersBalance: { rows: [any], total_game_balance: 0 }; // With balance
  totalBalance = 0;
  totalGamesBalancePercentage: number = 0;
  refreshIsClicked: boolean = false;

  // Smart Transfer Variable
  smartTransfer: boolean = JSON.parse(localStorage.getItem('user_data')).smart_transfer;
  disabledSmartTransferSwitch: boolean = false;

  // Other varible
  providerBalancesIsLoading: number = 0;
  memberBalanceIsLoading: number = 0;

  // To hold all subscription and unsubscribe when leaving the page 
  private subscriptions: Subscription[] = [];

  constructor(
    private transferService: PortalTransferHttpService,
    public dialogRef: MatDialogRef<WalletModalComponent>,
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    public gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService,
    private translateService: TranslateService,
    private loadingService: LoadingService
  ) {
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.providerBalancesLoadingBehaviourSubject.subscribe(val => {
        this.providerBalancesIsLoading = val;
      })
    );
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.memberBalanceLoadingBehaviourSubject.subscribe(val => {
        this.memberBalanceIsLoading = val;
      })
    );
    // Subscribe to get push update for PortalMemberProfileHttpService action message
    this.subscriptions.push(
      this.portalMemberProfileHttpService.messages$.subscribe(val => {
        this.messages$.next(val);
        this.isSuccess = this.portalMemberProfileHttpService.isSuccess;
        this.flashStyle = false;
      })
    );
    // Subscribe to get push update for member balance
    this.subscriptions.push(
      this.walletHttpService.memberBalance.subscribe(res => {
        this.wallet = res;
        if (this.wallet.currency_code != '-' && this.gameProvidersBalance != undefined) {
          this.getTotalBalance();
        }
      })
    );
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameProvidersBalance = res;
        if (this.gameProvidersBalance.rows.length > 0 && this.wallet != undefined) {
          this.getTotalBalance();
        }
      })
    );
  }

  ngOnInit() {
    // this.getSmartTransfer();
    this.refreshBalance();
  }

  ngOnDestroy() {
    this.subscriptions.map(sb => sb.unsubscribe());
  }

  onRestore() {
    if (this.gameProvidersBalance.total_game_balance > 0) {
      const transfers = [];
      this.gameProvidersBalance.rows.forEach(row => {
        if (row.balance > 0.00 && row.status === 1) {
          transfers.push(this.transferService.transferBy(row.id, 'all', 2));
        }
      });

      zip(...transfers).pipe(
        tap(() => {
          this.refreshBalance();
        }),
        catchError((error) => {
          this.refreshBalance();
          throw error;
        })
      ).subscribe();
    } else {
      this.messages$.next([this.translateService.instant('All credits are in Main Wallet')]);
      this.isSuccess = true;
      this.flashStyle = true;
    }
  }

  onRefresh() {
    if (this.providerBalancesIsLoading == 0) {
      this.refreshIsClicked = true;
      // this.eventEmitterService.onRefreshProviderBalance(false);
      this.refreshBalance();
    }
  }

  onSmartTransfer() {
    this.disabledSmartTransferSwitch = true;
    of(null).pipe(
      delay(3000), tap(() => this.disabledSmartTransferSwitch = false
      )).subscribe();
    this.portalMemberProfileHttpService.updateSmartTransfer().pipe(
      tap((res: any) => {
        this.smartTransfer = Boolean(res.smart_transfer);
      }),
      catchError((error) => {
        const smartTransferRef = this.smartTransfer;
        this.smartTransfer = null;
        of(null).pipe(
          delay(200), tap(() => this.smartTransfer = smartTransferRef
          )).subscribe();
        this.disabledSmartTransferSwitch = false;
        throw error;
      })
    ).subscribe();
  }

  private getSmartTransfer() {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    this.smartTransfer = Boolean(userData.smart_transfer);
  }

  private getProviderBalance() {
    this.gameCategoryHttpService.getGameCategoryListWithPromo(true).subscribe();
  }

  private getTotalBalance() {
    this.totalBalance = (+this.wallet.balance) + (+this.gameProvidersBalance.total_game_balance);
    this.totalGamesBalancePercentage = (+this.gameProvidersBalance.total_game_balance / +this.totalBalance) * 220;
    this.refreshIsClicked = false;
  }

  // Refresh balance after restore
  private refreshBalance() {
    forkJoin([
      this.gameCategoryHttpService.getGameCategoryListWithPromo(true),
      this.walletHttpService.getMemberBalance()
    ]).subscribe();
  }

}
